// Event Swup load page
document.addEventListener("swup:contentReplaced", event => {
    G_FUNC.initGlobalFunctions();
});
document.addEventListener("swup:transitionStart", event => {
    // jQuery('#swup').css('transform','translate3d(0, 60px, 0)');
    jQuery('html,body').scrollTop(0);
});
document.addEventListener("swup:transitionEnd", event => {
    // jQuery('#swup').css('transform','none');
});

// Instanciation Swup
const swup = new Swup({
    plugins: [new SwupOverlayTheme({
        // color: "#000045",
        color: "#f7f7f7",
        duration: 300,
        direction: 'to-right'
    })]
});



// On passe au site après l'instanciation de swup
jQuery(document).ready(function() {
    // jQuery('#swup').css('transform','none');
    jQuery('#offcanvas-full-screen').css('display','block');
    G_FUNC.initGlobalFunctions();
});



// Global functions
var G_FUNC = {

    initGlobalFunctions: function() {
        jQuery(document).foundation();
        this.menu_custom();
        this.owlCarousel();
        this.tilt();
        this.onScroll();
        this.callApi();
        this.article();
    },

    menu_custom: function(){
        // Update
            jQuery('#top-bar-menu a').click(function(){
                jQuery('#top-bar-menu li.active').removeClass('active');
                jQuery(this).parents('li').addClass('active');
            });
        // Fermeture menu mobile
            if(jQuery('#offcanvas-full-screen').hasClass('is-open')){
                jQuery('#top-bar-menu .top-bar-right .menu-icon[aria-expanded=true]').click();
            }
    },

    owlCarousel: function(){
        jQuery('.owl-carousel').owlCarousel({
            loop:true,
            margin:0,
            nav:true,
            navText: [
                '<i class="far fa-chevron-left"></i>',
                '<i class="far fa-chevron-right"></i>'
            ],
            responsive:{
                0:{
                    items:1,
                    center: false,
                    nav:true
                },
                600:{
                    items:2,
                    center: false,
                    nav:true
                },
                1000:{
                    items:3,
                    center: false,
                    nav:true,
                }
            }
        });
    },

    tilt: function(){
        jQuery('.js-tilt').tilt({
            // reset: false,
            // scale: 1.05
        });
    },

    onScroll: function(){
        jQuery(window).scroll(function(){
            // Si Formulaire ouvert
            if(jQuery("#search-form").is(":visible")){
                // On simule un clic pour fermer le Formulaire
                jQuery('#search').click();
            }
        });
    },

    callApi: function(){
        // Check if is localhost
            var url_wp_admin = '';
            if(location.hostname === "localhost" || location.hostname === "127.0.0.1"){
            url_wp_admin = '/nalo';
            }
        // -------------------------------------------------------------------------------------------------------------------
        // GUIDE INVESTISSEMENT
        // -------------------------------------------------------------------------------------------------------------------
            // SUBMIT FORM ------
            jQuery('.banniere .submit_banner').click(function(e){
                e.preventDefault();
                // Get data
                var banniere = jQuery(this).parents('.banniere');
                var mail = banniere.find('input.mail').val();
                // Call API
                console.log('---');
                console.log(mail);
                jQuery.ajax({ type: 'POST', dataType: "json", url: url_wp_admin + "/wp-admin/admin-ajax.php",
                    data: ({
                    action : 'call_api_investment_guide',
                    mail : mail
                    })
                }).done(function(result) {
                    console.log('+++++');
                    console.log(result);
                    banniere.find('.return_mess').text(result);
                });
            });
        // -------------------------------------------------------------------------------------------------------------------
        // SIMULATION
        // -------------------------------------------------------------------------------------------------------------------
            if(jQuery('#commencer-ma-simulation').length){
                // Style List Select Option
                    jQuery(".select_ui").selectmenu();
                // Largeur automatique input
                    jQuery.fn.textWidth = function(text, font) {
                        if (!jQuery.fn.textWidth.fakeEl) jQuery.fn.textWidth.fakeEl = jQuery('<span>').hide().appendTo(document.body);
                        jQuery.fn.textWidth.fakeEl.text(text || this.val() || this.text() || this.attr('placeholder')).css('font', font || this.css('font'));
                        return jQuery.fn.textWidth.fakeEl.width();
                    };
                    jQuery('.input_width_dynamic').on('input', function() {
                    var inputWidth = jQuery(this).textWidth();
                        jQuery(this).css({
                        width: parseInt(inputWidth + 37)
                    })
                    }).trigger('input');
                    function inputWidth(elem, minW, maxW) {
                        elem = jQuery(this);
                        console.log(elem)
                    }
                    var targetElem = jQuery('.input_width_dynamic');
                    inputWidth(targetElem);
                // CHANGE VALUE ------
                    jQuery('select[name="professional_status"]').on('selectmenuchange', function() {
                        if(jQuery(this).val() == 'independant'){
                            jQuery('.earning_type_bloc.other').addClass('hidden').removeClass('visible');
                            jQuery('.earning_type_bloc.independant').addClass('visible').removeClass('hidden');
                        }
                        else{
                            jQuery('.earning_type_bloc.other').addClass('visible').removeClass('hidden');
                            jQuery('.earning_type_bloc.independant').addClass('hidden').removeClass('visible');
                        }
                    });
                // SUBMIT FORM ------
                    jQuery('.bloc.simulation .submit_simulation').click(function(e){
                        e.preventDefault();
                        // Get data
                        var bloc = jQuery(this).parents('.bloc.simulation');
                        // Call API
                        jQuery.ajax({ type: 'POST', dataType: "json", url: url_wp_admin + "/wp-admin/admin-ajax.php",
                            data: ({
                            action : 'call_api_simulation',
                            age : bloc.find('input.age').val(),
                            earning_type : bloc.find('.visible select.earning_type').val(),
                            earnings : bloc.find('input.earnings').val(),
                            professional_status : bloc.find('select.professional_status').val(),
                            independant_earnings : 'bnc'
                            })
                        }).done(function(result) {
                            console.log('________++________');
                            console.log(result);
                            console.log('_______++_________');
                            // Si erreur
                            if(result[0] == 'non'){
                                bloc.find('.return_mess').text(result[1]);
                            }
                            // Si c'est ok
                            else if(result[0] == 'oui'){
                                window.location.replace('https://app.nalo.fr/components/onboard/#/introduction');
                                // document.cookie = "username1=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC";
                                // bloc.find('.return_mess').text(result);
                            }
                        });
                    });
            }
    },

    article: function(){
        // if(jQuery('body').hasClass('single-post')){
            // Var
                headerHeight = parseInt(jQuery('.header.header').height() + 1);
                duree_scroll = 1000;
            // -------------------------------------------------------------------------------------------------------------------
            // ON CLICK SCROLL ANCHOR
            // -------------------------------------------------------------------------------------------------------------------
                jQuery('a.scroll_anchor').click(function(e){
                    // Get data
                    var anchor_url = jQuery(this).attr('href');
                    var url = anchor_url, idx = url.indexOf("#");
                    var anchor_id = idx != -1 ? url.substring(idx+1) : "";
                    // Si ancre dans l'url
                    if(anchor_id.length){
                        // Si c'est dans la page - scroll
                        if(jQuery('#'+anchor_id).length){
                            // Smooth scroll
                            e.preventDefault();
                            jQuery("html, body").stop().animate({
                            scrollTop: (jQuery('#'+anchor_id).offset().top - headerHeight)
                            }, duree_scroll);
                        }
                        // Sinon, comportement classique du lien
                        else{
                            return true;
                        }
                    }
                });
            // -------------------------------------------------------------------------------------------------------------------
            // ON SCROLL
            // -------------------------------------------------------------------------------------------------------------------
                var ancres = jQuery('#content_article h2');
                var ancresY = [];
                var current = 0;
                // Get H2 top posision
                ancres.each(function(index){
                    ancresY.push( jQuery(this).offset().top );
                });
                // on scroll
                jQuery(window).scroll(function(event){
                    // Check current h2 visible (last one)
                    var scrollTop = jQuery(window).scrollTop();
                    jQuery.each( ancresY, function( key, value ) {
                        if(scrollTop >= (value-150) ){
                            current = key;
                        }
                    });
                    // Active the current sommaire link
                    jQuery('#sticky_sommaire ul li.active').addClass('read').removeClass('active');
                    jQuery('#sticky_sommaire ul li:nth-child('+parseInt(current+1)+')').addClass('active');
                });
            // -------------------------------------------------------------------------------------------------------------------
            // ON LOAD PAGE WITH ANCHOR
            // -------------------------------------------------------------------------------------------------------------------
                var hash = window.location.hash;
                if ( hash == '' || hash == '#' || hash == undefined ) return false;
                var target = jQuery(hash);
                target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    jQuery('html,body').stop().animate({
                    scrollTop: target.offset().top - headerHeight
                    }, duree_scroll);
                }
        // }
    }

    // stickySommaire: function(){
    //     if(jQuery('#sticky_sommaire').length){
    //         var sommaire = jQuery('#sticky_sommaire .sommaire');
    //         jQuery(window).scroll(function (event) {
    //             var scrollTop = jQuery(window).scrollTop();
    //             console.log('----');
    //             console.log(scrollTop);
    //             console.log(sommaire.offset().top);
    //             if(scrollTop > sommaire.offset().top){
    //                 sommaire.addClass('fixed');
    //             }
    //             sommaire.width(sommaire.parent().width());
    //         });   
    //     }
    // }

};